import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Profile from "./components/Profile/Profile";
import { getToken } from "./helpers";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import FileUploader from "./components/FileUpload/FileUploader";
import ListFiles from "./components/FileUpload/ListFiles";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={getToken()==null ? <SignIn /> : <Navigate to="/profile" />}
      />
      <Route path="/fileUploader" element={<FileUploader />} />
      <Route path="/signIn" element={<SignIn />} />      
      <Route path="/signup" element={<SignUp />} />
      <Route path="/listFiles" element={<ListFiles />} />
      <Route
        path="/profile"
        element={getToken() ? <Profile /> : <Navigate to="/signIn" />}
      />
    </Routes>
  );
};

export default AppRoutes;
