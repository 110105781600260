import React, { useEffect } from 'react';
import { useState } from 'react';
import "rsuite/dist/rsuite.css"
import { Button,Card, Col, Form, Input, message, Row, Spin, Pagination } from "antd";
import { API, DOMAIN } from '../../constant';
import { Uploader } from 'rsuite';
import { getToken } from '../../helpers';
import axios from 'axios';
import { Avatar, List, FlexboxGrid } from 'rsuite';
import ImageIcon from '@rsuite/icons/legacy/Image';
import FilmIcon from '@rsuite/icons/legacy/Film';
import UserCircleIcon from '@rsuite/icons/legacy/UserCircleO';
import { CgRemove, CgTrash } from 'react-icons/cg';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const ListFiles = () => {

  var currenPageNo=1;

  const [totalItems, setTotalItems] =  useState(0);
    const [jsonData,setModelDetails] = useState({
        "data": [
            {
                "id": 10,
                "attributes": {
                    "name": "Test Model",
                    "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                    "category": "2024", 
                    "gallery": {
                        "imageUrl": "/uploads/Screenshot_2024_03_05_at_10_38_39_AM_1750b44e65.png",
                        "modelUrl": "/uploads/woman_a90e8ab619.fbx"
                    },
                    "createdAt": "2024-05-22T10:43:45.826Z",
                    "updatedAt": "2024-05-22T10:43:45.826Z",
                    "publishedAt": "2024-05-22T10:43:45.823Z"
                }
            }
        ],
        "meta": {
            "pagination": {
                "page": 1,
                "pageSize": 25,
                "pageCount": 1,
                "total": 1
            }
        }
    });
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    };

useEffect(()=>{
  fetchData(1);
},[]);

const fetchData=(pageNo)=>{
  axios.get(`${API}/model-details?pagination[pageSize]=10&pagination[page]=`+pageNo, config).then((response) => {  
    console.log(response);
    
    setModelDetails(response.data);
    setTotalItems(response.data.meta.pagination.total);
    });

};

const deleteItem=(id)=>{
  axios.delete(`${API}/model-details/`+id, config).then((response) => {  
    console.log(response);
    fetchData(currenPageNo);
    });

};

const handlePageClick = (page, pageSize) => {  
  console.log(`User requested page number ${page}`);
  fetchData(page);
  currenPageNo=page;
};

      const styleCenter = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60px'
      };
      
      const slimText = {
        fontSize: '0.666em',
        color: '#97969B',
        fontWeight: 'lighter',
        paddingBottom: 5
      };
      
      const titleStyle = {
        paddingBottom: 5,
        whiteSpace: 'nowrap',
        fontWeight: 500
      };

      const topBannerStyle = {
        paddingBottom: 5,
        whiteSpace: 'nowrap',
        fontWeight: 800,
        fontSize: 16
      };
      
      const dataStyle = {
        fontSize: '1.2em',
        fontWeight: 500
      };

      const imgAspect ={
        width: 60,
        height: 60,
        objectFit: 'contain',
        border: '2px solid rgba(0,0,0, 0.1)',
        borderRadius: '10px'
      };
    
      return (
        <Card className="profile_page_card">
           <Row>
      <h3>Models<br/><br/> </h3>
     </Row>
        <List style={{color :'black'}} hover>
            <FlexboxGrid>                
                {/*base info*/}               
                <FlexboxGrid.Item
                  colspan={2}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden'                 
                  }}
                >
                  <div style={topBannerStyle}><ImageIcon style={{ fontSize: '1.5em', marginLeft:'20' }}/></div>
                  
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  colspan={4}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden'                 
                  }}
                >
                  <div style={topBannerStyle}>Name</div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  colspan={2}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden',                                   
                  }}
                >
                  <div style={topBannerStyle}>Category</div>
                                  </FlexboxGrid.Item>
                                  <FlexboxGrid.Item
                  colspan={5}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden',                                   
                  }}
                >
                  <div style={topBannerStyle}>Description</div>
                                  </FlexboxGrid.Item>
                
                {/*peak data*/}
                <FlexboxGrid.Item colspan={6} style={styleCenter}>
                  <div style={{ textAlign: 'right' }}>
                    {/* <div style={slimText}>Peak</div> */}
                   
                    <div style={topBannerStyle}>Created At                    
                    </div>
                  </div>
                </FlexboxGrid.Item>
                
              </FlexboxGrid>
          {jsonData.data.map((item, index) => (
            <List.Item key={item['attributes'].name} index={index + 1}>
              <FlexboxGrid>            
                <FlexboxGrid.Item
                  colspan={2}                  
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    fontSize: 16,
                    marginLeft: 10                    
                  }}
                >
                  { 
                  <Zoom>
                    <div>
                     <img src={`${DOMAIN}`+item['attributes'].gallery.imageUrl} style={imgAspect}/>
                    </div>
                  </Zoom>  
                   }
                </FlexboxGrid.Item>
                <FlexboxGrid.Item
                  colspan={4}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden',
                    fontSize: 16                    
                  }}
                >
                  <div style={titleStyle}>{item['attributes'].name}</div>                 
                </FlexboxGrid.Item>

                <FlexboxGrid.Item
                  colspan={2}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'hidden',
                    fontSize: 16                    
                  }}
                >
                  <div style={titleStyle}>{item['attributes'].category}</div>                  
                </FlexboxGrid.Item>

                <FlexboxGrid.Item
                  colspan={5}
                  style={{
                    ...styleCenter,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    overflow: 'scroll',
                    fontSize: 16                    
                  }}
                >
                  <div style={titleStyle}>{item['attributes'].description}</div>                  
                </FlexboxGrid.Item>

                {/*peak data*/}
                <FlexboxGrid.Item colspan={6} style={styleCenter}>
                  <div style={{ textAlign: 'right' }}>
                    {/* <div style={slimText}>Peak</div> */}
                   
                    <div style={dataStyle}>{
                        new Date(item['attributes'].createdAt).toLocaleString()
                    }</div>
                  </div>
                </FlexboxGrid.Item>
                
                <FlexboxGrid.Item
                  colspan={4}
                  style={{
                    ...styleCenter
                  }}
                >
                   <Button type="link" onClick={()=>deleteItem(item['id'])}>
        <CgTrash size={25} />
      </Button>
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
          ))}
        </List>
        <Pagination style={{marginTop:'25px'}} defaultCurrent={1} total={totalItems} showSizeChanger={false} pageSize={10} onChange={handlePageClick} />
        </Card>
      );
};

export default ListFiles;
