import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Typography,
} from "antd";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import useScreenSize from "../../hooks/useScreenSize";
import { API } from "../../constant";
import { setToken, getToken } from "../../helpers";
import axios from 'axios';

const SignIn = () => {
  const { isDesktopView } = useScreenSize();
  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  var userRole = {
    "id": 16,
    "username": "Developer",
    "email": "devtest@gmail.com",
    "provider": "local",
    "confirmed": true,
    "blocked": false,
    "createdAt": "2024-06-17T06:19:05.613Z",
    "updatedAt": "2024-06-17T06:19:05.613Z",
    "role": {
        "id": 1,
        "name": "Authenticated",
        "description": "Default role given to authenticated user.",
        "type": "authenticated",
        "createdAt": "2024-05-10T09:36:26.773Z",
        "updatedAt": "2024-05-21T07:05:19.438Z"
    }
};

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      const value = {
        identifier: values.email,
        password: values.password,
      };
      const response = await fetch(`${API}/auth/local`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {

        // set the token
        setToken(data.jwt);
        
        const config = {
          headers: {
            Authorization: `Bearer ${getToken()}`
          }
        };
        
        axios.get(`${API}/users/`+data.user.id+'?populate=role', config).then((response) => {  
          console.log(response.data);
          userRole=response.data;
        });
        setTimeout(
          () => {
            if(userRole.role.type=='super_admin')
              {
                // set the user
                setUser(data.user);                    
                message.success(`Welcome back ${data.user.username}!`);          
                navigate("/profile", { replace: true });
              }else{
                setError(error?.message ?? "Access Denied");
              }
          }, 
          3000
        );
        
      }
    } catch (error) {
      console.error(error);
      setError(error?.message ?? "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <Row align="middle">
        <Col span={isDesktopView ? 8 : 24} offset={isDesktopView ? 8 : 0}>
          <Card title="SignIn">
            {error ? (
              <Alert
                className="alert_error"
                message={error}
                type="error"
                closable
                afterClose={() => setError("")}
              />
            ) : null}
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login_submit_btn"
                >
                  Login {isLoading && <Spin size="small" />}
                </Button>
              </Form.Item>
            </Form>
            <Typography.Paragraph className="form_help_text">
              New User? <Link to="/signup">Sign Up</Link>
            </Typography.Paragraph>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default SignIn;
