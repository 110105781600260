import { Button, Space } from "antd";
import React from "react";
import { CgAdd, CgAddR, CgFileAdd, CgList, CgWebsite } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken, getToken } from "../../helpers";

const AppHeader = () => {
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    setUser(undefined);
    navigate("/signIn", { replace: true });
  };

  return (
    <Space className="header_space">
     {getToken()!=null && <Button className="header_space_brand" href="/fileUploader" type="link">
        <CgFileAdd style={{marginTop: 10}} size={42} />
      </Button>}
      {getToken()!=null&&<Button className="header_space_brand" href="/listFiles" type="link">
        <CgList style={{marginTop: 10}} size={42} />
      </Button>}
      <Space className="auth_buttons">
        {user ? (
          <>
            <Button className="auth_button_login" style={{textTransform: 'capitalize', fontWeight:'bold'}} href="/profile" type="link">
              {user.username}
            </Button>
            <Button
              className="auth_button_signUp"
              type="primary"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            {/* <Button className="auth_button_login" href="/signIn" type="link">
              Login
            </Button>
            <Button
              className="auth_button_signUp"
              href="/signup"
              type="primary"
            >
              SignUp
            </Button> */}
          </>
        )}
      </Space>
    </Space>
  );
};

export default AppHeader;
