import React from 'react';
import "rsuite/dist/rsuite.css"
import { Button,Card, Col, Form, Input, message, Row, Spin } from "antd";
import { API } from '../../constant';
import { Uploader, Progress } from 'rsuite';
import { getToken } from '../../helpers';
import axios from 'axios';
import {YearPicker} from 'react-date-dropdown';


const FileUploader = () => {

  const [imageList, setImageList] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const uploader = React.useRef(); 
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [isProgressOpen, setIsProgressOpen] = React.useState(false);
  const [modelName, setName] = React.useState('');
  const [isModelNameAlreadyExists, setIsModelNameAlreadyExists] = React.useState(false); 
  const [uploadForm] =Form.useForm();

  
  var [selectedYear,SetYear] = React.useState('Elizabethan');
  var [selectedGender,SetGender]= React.useState('Male');

  const handleYearChange = (event) => {

    SetYear(event.target.value);
 
  };

  const handleGenderChange = (event) => {

    SetGender(event.target.value);
 
  };
  var progressStyle = {
    width: 150,
    display: 'inline-block',
    marginLeft: 20  
  };

  const [jsonData,setModelDetails] = React.useState({
    "data": [
        {
            "id": 10,
            "attributes": {
                "name": "Test Model",
                "description": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                "category": "2024", 
                "Gender":"male",
                "gallery": {
                    "imageUrl": "/uploads/Screenshot_2024_03_05_at_10_38_39_AM_1750b44e65.png",
                    "modelUrl": "/uploads/woman_a90e8ab619.fbx"
                },
                "createdAt": "2024-05-22T10:43:45.826Z",
                "updatedAt": "2024-05-22T10:43:45.826Z",
                "publishedAt": "2024-05-22T10:43:45.823Z"
            }
        }
    ],
    "meta": {
        "pagination": {
            "page": 1,
            "pageSize": 25,
            "pageCount": 1,
            "total": 1
        }
    }
});
const config = {
  headers: {
    Authorization: `Bearer ${getToken()}`
  }
};
React.useEffect(()=>{
fetchData();
},[]);

const fetchData=()=>{
axios.get(`${API}/model-details`, config).then((response) => {  
console.log(response);
setModelDetails(response.data);
});

};

  const handleProfileUpdate = (data) =>{
  const formData = new FormData();
          formData.append('files', fileList[0].blobFile, fileList[0].blobFile.name);
          formData.append('files', imageList[0].blobFile, imageList[0].blobFile.name);
          const config = {
            headers: {
              Authorization: `Bearer ${getToken()}`
            },
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              let precentage = Math.floor((loaded * 100) / total);
              // console.log("options");
              // console.log(precentage);
              // if (precentage < 100) {
              //   console.log(precentage);
              // }
              setUploadProgress(precentage);
            }
          };
           setIsProgressOpen(true);
          axios.post(`${API}/upload`, formData, config).then((response) => {            
            console.log(response.data[0]);
            setIsProgressOpen(false);

            const jsonData={
              "data": {
                  "name": data.username,
                  "description": data.about,
                  "category": selectedYear,
                  "Gender": selectedGender,
                  "gallery": {
                      "imageUrl": response.data[1].url,
                      "modelUrl": response.data[0].url
                  }
              }
          };

            axios.post(`${API}/model-details`, jsonData, config).then((response) => {            
              console.log(response.data);
               uploadForm.resetFields();
               setName('');
            });
          });
      
  };

  return (    
    <Card className="profile_page_card">
     <Row>
      <h3>Upload 4DS<br/><br/> </h3>
     </Row>
      <Form
      form={uploadForm}
        layout="vertical"
        onFinish={handleProfileUpdate}       
      >
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Model Name"
              name="username"
              style={{fontWeight :'bold'}}
              rules={[
                {
                  required: true,
                  message: "Model name is required!",
                  type: "string",
                },
                {
                  message: 'Model name already exists',
                  validator: (_, value) => {

                    setIsModelNameAlreadyExists(false);
                    var ModelNameAlreadyExists=false;

                    var formattedValue=value.toString().trim();

                    jsonData.data.forEach((item, index)=>{
                      if(item['attributes'].name==formattedValue){
                        setIsModelNameAlreadyExists(true);
                        ModelNameAlreadyExists=true;
                      }
                    });

                    if (ModelNameAlreadyExists) {
                      return Promise.reject('Some message here');
                    } else {
                      return Promise.resolve();
                    }
                   }
                 }
              ]}
            >
              <Input  style={{fontWeight :'normal'}} placeholder="Model Name" 
              onChange={(e) => setName(e.target.value)} 
              />
            </Form.Item>
          </Col>       
          <Col  md={8} lg={8} sm={24} xs={24}>
          <Form.Item
           style={{fontWeight :'bold'}}
          label="Category"
          name="Category"
          >
           <select style={{fontWeight :'normal'}} value={selectedYear} onChange={handleYearChange}>

<option value="Elizabethan">Elizabethan</option>

<option value="20th century">20th century</option>

<option value="Late Victorian">Late Victorian</option>

<option value="Early Victorian">Early Victorian</option>

<option value="Edwardian">Edwardian</option>

<option value="Napoleonic">Napoleonic</option>

<option value="Contemporary">Contemporary</option>

<option value="Georgian">Georgian</option>


</select>

          </Form.Item>
          </Col>    

          <Col  md={8} lg={8} sm={24} xs={24}>
          <Form.Item
           style={{fontWeight :'bold'}}
          label="Gender"
          name="Gender"
          >
           <select style={{fontWeight :'normal'}} value={selectedGender} onChange={handleGenderChange}>

<option value="Male">Male</option>

<option value="Female">Female</option>

<option value="Others">Others</option>


</select>

          </Form.Item>
          </Col> 

          <Col span={24}>
            <Form.Item
              label="Description"
              name="about"
              style={{fontWeight :'bold'}}
              rules={[
                {
                  required: false,
                  type: "string"
                },
              ]}
            >
              <Input.TextArea style={{fontWeight :'normal'}} placeholder="Description" rows={6} />
            </Form.Item>
          </Col>
          <Col>
          <Form.Item
           label="Upload Image"
           name="uploadImage"
           style={{fontWeight :'bold'}}
          >
          <Uploader
        fileList={imageList}
        action=''
        onChange={setImageList}
        shouldUpload={x=>false}
        multiple={false}
        ref={uploader}
        disabled={imageList.length==1}
        accept="image/png, image/jpeg"
      >
        <Button>Browse</Button>
      </Uploader>
      <hr/>
      </Form.Item>
      
      <Form.Item
      label="Upload 4DS"
      name="upload4ds" 
      style={{fontWeight :'bold'}}     
      >
      <Uploader
        fileList={fileList}
        action=''
        onChange={setFileList}
        shouldUpload={x=>false}
        multiple={false}
        ref={uploader}
        disabled={fileList.length==1}
        accept="application/4ds"
      >
        <Button>Browse</Button>
      </Uploader>
      <hr/>
      </Form.Item>

      <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="ant-btn ant-btn-primary ant-btn-lg"
                  disabled={!fileList.length||!imageList.length||modelName==''||isModelNameAlreadyExists==true||isProgressOpen}
                >      
        Upload
      </Button>
      <div style={progressStyle}>
      {isProgressOpen && <Progress.Line      
      percent={uploadProgress} />}
      </div>
      </Form.Item>
          </Col>
        </Row>        
      </Form>
    </Card>
    
  );
};

export default FileUploader;
